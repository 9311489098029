import React from 'react';

function Page404(props) {
	return (
		<div>
			<h1>404: Nothing found here</h1>
		</div>
	);
}

export default Page404;