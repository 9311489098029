import React from "react"
import "../scss/Footer.scss"

function Footer(props) {
	return (
		<div className="footer">
			<h2>Woah You Reached the End</h2>
			<h3>
				© hoomanns
				<br />
				All Rights Reserved
			</h3>
		</div>
	)
}

export default Footer
