import React from "react"
import { GodsList } from "./"

function GodsListPage(props) {
	return (
		<div className="gods-list-page">
			<GodsList />
		</div>
	)
}

export default GodsListPage
