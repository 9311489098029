import React from "react"
import { Search } from "./"
import "../scss/SearchPage.scss"

function SearchPage(props) {
	return (
		<div id="SearchPage">
			<Search />
		</div>
	)
}

export default SearchPage
