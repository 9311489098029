export const UPDATE_POSTS = "UPDATE_POSTS"
export const ADD_POST = "ADD_POST"
export const ADD_COMMENT = "ADD_COMMENT"
export const ADD_COMMENT_LIKE = "ADD_COMMENT_LIKE"
export const UPDATE_COMMENTS = "UPDATE_COMMENTS"
export const ADD_POST_LIKE = "ADD_POST_LIKE"
export const REMOVE_POST = "REMOVE_POST"
export const REMOVE_COMMENT = "REMOVE_COMMENT"
export const REMOVE_COMMENT_LIKE = "REMOVE_COMMENT_LIKE"
export const REMOVE_POST_LIKE = "REMOVE_POST_LIKE"

export const ADD_SEARCH_RESULTS = "ADD_SEARCH_RESULTS"
//login
export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

//signup
export const SIGNUP_START = "SIGNUP_START"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SIGNUP_FAILURE = "SIGNUP_FAILURE"

export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE"
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const LOGOUT = "LOGOUT"

export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAILED = "EDIT_USER_FAILED"
export const EDIT_USER_START = "EDIT_USER_START"

export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS"
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE"
export const FETCH_USER_PROFILE_START = "FETCH_USER_PROFILE_START"
export const CLEAR_PROFILE_STATE = "CLEAR_PROFILE_STATE"

export const ADD_GODS_LIST = "ADD_GODS_LIST"
export const REMOVE_GODS_LIST = "REMOVE_GODS_LIST"
export const ADD_GOD = "ADD_GOD"
export const REMOVE_GOD = "REMOVE_GOD"

export const SET_MEDIA_LIST = "SET_MEDIA_LIST"
