import React from "react"
import { UserPhotoURL } from "../constants"
import { Blurhash } from "react-blurhash"

function UserPhoto(props) {
	const { user, className } = props
	let emoji = user.emoji
	if (!emoji) {
		var emojis = [
			"😄",
			"😃",
			"😀",
			"😊",
			"☺",
			"😉",
			"😍",
			"😘",
			"😚",
			"😗",
			"😙",
			"😜",
			"😝",
			"😛",
			"😳",
			"😁",
			"😔",
			"😌",
			"😒",
			"😞",
			"😣",
			"😢",
			"😂",
			"😭",
			"😪",
			"😥",
			"😰",
			"😅",
			"😓",
			"😩",
			"😫",
			"😨",
			"😱",
			"😠",
			"😡",
			"😤",
			"😖",
			"😆",
			"😋",
			"😷",
			"😎",
			"😴",
			"😵",
			"😲",
			"😟",
			"😦",
			"😧",
			"😈",
			"👿",
			"😮",
			"😬",
			"😐",
			"😕",
			"😯",
			"😶",
			"😇",
			"😏",
			"😑",
			"👲",
			"👳",
			"👮",
			"👷",
			"💂",
			"👶",
			"👦",
			"👧",
			"👨",
			"👩",
			"👴",
			"👵",
			"👱",
			"👼",
			"👸",
			"😺",
			"😸",
			"😻",
			"😽",
			"😼",
			"🙀",
			"😿",
			"😹",
			"😾",
			"👹",
			"👺",
			"🙈",
			"🙉",
			"🙊",
			"💀",
			"👽",
			"💩",
			"🔥",
			"✨",
			"🌟",
			"💫",
			"💥",
			"💢",
			"💦",
			"💧",
			"💤",
			"💨",
			"👂",
			"👀",
			"👃",
			"👅",
			"👄",
			"👍",
			"👎",
			"👌",
			"👊",
			"✊",
			"✌",
			"👋",
			"✋",
			"👐",
			"👆",
			"👇",
			"👉",
			"👈",
			"🙌",
			"🙏",
			"☝",
			"👏",
			"💪",
			"🚶",
			"🏃",
			"💃",
			"👫",
			"👪",
			"👬",
			"👭",
			"💏",
			"💑",
			"👯",
			"🙆",
			"🙅",
			"💁",
			"🙋",
			"💆",
			"💇",
			"💅",
			"👰",
			"🙎",
			"🙍",
			"🙇",
			"🎩",
			"👑",
			"👒",
			"👟",
			"👞",
			"👡",
			"👠",
			"👢",
			"👕",
			"👔",
			"👚",
			"👗",
			"🎽",
			"👖",
			"👘",
			"👙",
			"💼",
			"👜",
			"👝",
			"👛",
			"👓",
			"🎀",
			"🌂",
			"💄",
			"💛",
			"💙",
			"💜",
			"💚",
			"❤",
			"💔",
			"💗",
			"💓",
			"💕",
			"💖",
			"💞",
			"💘",
			"💌",
			"💋",
			"💍",
			"💎",
			"👤",
			"👥",
			"💬",
			"👣",
			"💭",
			"🐶",
			"🐺",
			"🐱",
			"🐭",
			"🐹",
			"🐰",
			"🐸",
			"🐯",
			"🐨",
			"🐻",
			"🐷",
			"🐽",
			"🐮",
			"🐗",
			"🐵",
			"🐒",
			"🐴",
			"🐑",
			"🐘",
			"🐼",
			"🐧",
			"🐦",
			"🐤",
			"🐥",
			"🐣",
			"🐔",
			"🐍",
			"🐢",
			"🐛",
			"🐝",
			"🐜",
			"🐞",
			"🐌",
			"🐙",
			"🐚",
			"🐠",
			"🐟",
			"🐬",
			"🐳",
			"🐋",
			"🐄",
			"🐏",
			"🐀",
			"🐃",
			"🐅",
			"🐇",
			"🐉",
			"🐎",
			"🐐",
			"🐓",
			"🐕",
			"🐖",
			"🐁",
			"🐂",
			"🐲",
			"🐡",
			"🐊",
			"🐫",
			"🐪",
			"🐆",
			"🐈",
			"🐩",
			"🐾",
			"💐",
			"🌸",
			"🌷",
			"🍀",
			"🌹",
			"🌻",
			"🌺",
			"🍁",
			"🍃",
			"🍂",
			"🌿",
			"🌾",
			"🍄",
			"🌵",
			"🌴",
			"🌲",
			"🌳",
			"🌰",
			"🌱",
			"🌼",
			"🌐",
			"🌞",
			"🌝",
			"🌚",
			"🌑",
			"🌒",
			"🌓",
			"🌔",
			"🌕",
			"🌖",
			"🌗",
			"🌘",
			"🌜",
			"🌛",
			"🌙",
			"🌍",
			"🌎",
			"🌏",
			"🌋",
			"🌌",
			"🌠",
			"⭐",
			"☀",
			"⛅",
			"☁",
			"⚡",
			"☔",
			"❄",
			"⛄",
			"🌀",
			"🌁",
			"🌈",
			"🌊",
			"🎍",
			"💝",
			"🎎",
			"🎒",
			"🎓",
			"🎏",
			"🎆",
			"🎇",
			"🎐",
			"🎑",
			"🎃",
			"👻",
			"🎅",
			"🎄",
			"🎁",
			"🎋",
			"🎉",
			"🎊",
			"🎈",
			"🎌",
			"🔮",
			"🎥",
			"📷",
			"📹",
			"📼",
			"💿",
			"📀",
			"💽",
			"💾",
			"💻",
			"📱",
			"☎",
			"📞",
			"📟",
			"📠",
			"📡",
			"📺",
			"📻",
			"🔊",
			"🔉",
			"🔈",
			"🔇",
			"🔔",
			"🔕",
			"📢",
			"📣",
			"⏳",
			"⌛",
			"⏰",
			"⌚",
			"🔓",
			"🔒",
			"🔏",
			"🔐",
			"🔑",
			"🔎",
			"💡",
			"🔦",
			"🔆",
			"🔅",
			"🔌",
			"🔋",
			"🔍",
			"🛁",
			"🛀",
			"🚿",
			"🚽",
			"🔧",
			"🔩",
			"🔨",
			"🚪",
			"🚬",
			"💣",
			"🔫",
			"🔪",
			"💊",
			"💉",
			"💰",
			"💴",
			"💵",
			"💷",
			"💶",
			"💳",
			"💸",
			"📲",
			"📧",
			"📥",
			"📤",
			"✉",
			"📩",
			"📨",
			"📯",
			"📫",
			"📪",
			"📬",
			"📭",
			"📮",
			"📦",
			"📝",
			"📄",
			"📃",
			"📑",
			"📊",
			"📈",
			"📉",
			"📜",
			"📋",
			"📅",
			"📆",
			"📇",
			"📁",
			"📂",
			"✂",
			"📌",
			"📎",
			"✒",
			"✏",
			"📏",
			"📐",
			"📕",
			"📗",
			"📘",
			"📙",
			"📓",
			"📔",
			"📒",
			"📚",
			"📖",
			"🔖",
			"📛",
			"🔬",
			"🔭",
			"📰",
			"🎨",
			"🎬",
			"🎤",
			"🎧",
			"🎼",
			"🎵",
			"🎶",
			"🎹",
			"🎻",
			"🎺",
			"🎷",
			"🎸",
			"👾",
			"🎮",
			"🃏",
			"🎴",
			"🀄",
			"🎲",
			"🎯",
			"🏈",
			"🏀",
			"⚽",
			"⚾",
			"🎾",
			"🎱",
			"🏉",
			"🎳",
			"⛳",
			"🚵",
			"🚴",
			"🏁",
			"🏇",
			"🏆",
			"🎿",
			"🏂",
			"🏊",
			"🏄",
			"🎣",
			"☕",
			"🍵",
			"🍶",
			"🍼",
			"🍺",
			"🍻",
			"🍸",
			"🍹",
			"🍷",
			"🍴",
			"🍕",
			"🍔",
			"🍟",
			"🍗",
			"🍖",
			"🍝",
			"🍛",
			"🍤",
			"🍱",
			"🍣",
			"🍥",
			"🍙",
			"🍘",
			"🍚",
			"🍜",
			"🍲",
			"🍢",
			"🍡",
			"🍳",
			"🍞",
			"🍩",
			"🍮",
			"🍦",
			"🍨",
			"🍧",
			"🎂",
			"🍰",
			"🍪",
			"🍫",
			"🍬",
			"🍭",
			"🍯",
			"🍎",
			"🍏",
			"🍊",
			"🍋",
			"🍒",
			"🍇",
			"🍉",
			"🍓",
			"🍑",
			"🍈",
			"🍌",
			"🍐",
			"🍍",
			"🍠",
			"🍆",
			"🍅",
			"🌽",
			"🏠",
			"🏡",
			"🏫",
			"🏢",
			"🏣",
			"🏥",
			"🏦",
			"🏪",
			"🏩",
			"🏨",
			"💒",
			"⛪",
			"🏬",
			"🏤",
			"🌇",
			"🌆",
			"🏯",
			"🏰",
			"⛺",
			"🏭",
			"🗼",
			"🗾",
			"🗻",
			"🌄",
			"🌅",
			"🌃",
			"🗽",
			"🌉",
			"🎠",
			"🎡",
			"⛲",
			"🎢",
			"🚢",
			"⛵",
			"🚤",
			"🚣",
			"⚓",
			"🚀",
			"✈",
			"💺",
			"🚁",
			"🚂",
			"🚊",
			"🚉",
			"🚞",
			"🚆",
			"🚄",
			"🚅",
			"🚈",
			"🚇",
			"🚝",
			"🚋",
			"🚃",
			"🚎",
			"🚌",
			"🚍",
			"🚙",
			"🚘",
			"🚗",
			"🚕",
			"🚖",
			"🚛",
			"🚚",
			"🚨",
			"🚓",
			"🚔",
			"🚒",
			"🚑",
			"🚐",
			"🚲",
			"🚡",
			"🚟",
			"🚠",
			"🚜",
			"💈",
			"🚏",
			"🎫",
			"🚦",
			"🚥",
			"⚠",
			"🚧",
			"🔰",
			"⛽",
			"🏮",
			"🎰",
			"♨",
			"🗿",
			"🎪",
			"🎭",
			"📍",
			"🚩",
			"⬆",
			"⬇",
			"⬅",
			"➡",
			"🔠",
			"🔡",
			"🔤",
			"↗",
			"↖",
			"↘",
			"↙",
			"↔",
			"↕",
			"🔄",
			"◀",
			"▶",
			"🔼",
			"🔽",
			"↩",
			"↪",
			"ℹ",
			"⏪",
			"⏩",
			"⏫",
			"⏬",
			"⤵",
			"⤴",
			"🆗",
			"🔀",
			"🔁",
			"🔂",
			"🆕",
			"🆙",
			"🆒",
			"🆓",
			"🆖",
			"📶",
			"🎦",
			"🈁",
			"🈯",
			"🈳",
			"🈵",
			"🈴",
			"🈲",
			"🉐",
			"🈹",
			"🈺",
			"🈶",
			"🈚",
			"🚻",
			"🚹",
			"🚺",
			"🚼",
			"🚾",
			"🚰",
			"🚮",
			"🅿",
			"♿",
			"🚭",
			"🈷",
			"🈸",
			"🈂",
			"Ⓜ",
			"🛂",
			"🛄",
			"🛅",
			"🛃",
			"🉑",
			"㊙",
			"㊗",
			"🆑",
			"🆘",
			"🆔",
			"🚫",
			"🔞",
			"📵",
			"🚯",
			"🚱",
			"🚳",
			"🚷",
			"🚸",
			"⛔",
			"✳",
			"❇",
			"❎",
			"✅",
			"✴",
			"💟",
			"🆚",
			"📳",
			"📴",
			"🅰",
			"🅱",
			"🆎",
			"🅾",
			"💠",
			"➿",
			"♻",
			"♈",
			"♉",
			"♊",
			"♋",
			"♌",
			"♍",
			"♎",
			"♏",
			"♐",
			"♑",
			"♒",
			"♓",
			"⛎",
			"🔯",
			"🏧",
			"💹",
			"💲",
			"💱",
			"©",
			"®",
			"™",
			"〽",
			"〰",
			"🔝",
			"🔚",
			"🔙",
			"🔛",
			"🔜",
			"❌",
			"⭕",
			"❗",
			"❓",
			"❕",
			"❔",
			"🔃",
			"🕛",
			"🕧",
			"🕐",
			"🕜",
			"🕑",
			"🕝",
			"🕒",
			"🕞",
			"🕓",
			"🕟",
			"🕔",
			"🕠",
			"🕕",
			"🕖",
			"🕗",
			"🕘",
			"🕙",
			"🕚",
			"🕡",
			"🕢",
			"🕣",
			"🕤",
			"🕥",
			"🕦",
			"✖",
			"➕",
			"➖",
			"➗",
			"♠",
			"♥",
			"♣",
			"♦",
			"💮",
			"💯",
			"✔",
			"☑",
			"🔘",
			"🔗",
			"➰",
			"🔱",
			"🔲",
			"🔳",
			"◼",
			"◻",
			"◾",
			"◽",
			"▪",
			"▫",
			"🔺",
			"⬜",
			"⬛",
			"⚫",
			"⚪",
			"🔴",
			"🔵",
			"🔻",
			"🔶",
			"🔷",
			"🔸",
			"🔹",
		]
		emoji = emojis[Math.floor(Math.random() * emojis.length)]
	}
	if (!user.avatar) {
		return (
			<div className={"emoji-avatar " + className}>
				<p>{emoji}</p>
			</div>
		)
	}
	return (
		<>
			<div className={`${className} user-avatar`}>
				{user.avatarBlurhash && <Blurhash hash={user.avatarBlurhash} />}
				<img
					src={UserPhotoURL(user.avatar)}
					alt={emoji || "User Avatar"}
					crossOrigin="true"
					// onLoad={setIsPhotoReady(true)}
				/>
			</div>
		</>
	)
}
export default UserPhoto
